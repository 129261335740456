"use client";

import { FC } from "react";
import LocationInput from "../LocationInput";
import RentalCarDatesRangeInput from "./RentalCarDatesRangeInput";
import RentalCarTimesRangeInput from "./RentalCarTimesRangeInput";
import DropOffLocationInput from "../DropOffLocationInput";
import { useData } from "data/data-provider";
import { LinearGradient } from "react-text-gradients";

export interface RentalCarSearchFormProps {}

const RentalCarSearchForm: FC<RentalCarSearchFormProps> = () => {
  const {
    dropOffLocation,
    setPickupLocation,
    setDropoffLocation,
    setSearchedPickupLocation,
    setSearchedDropoffLocation,
    pickupLocation,
    setDropoffLocationType,
    setSameDropoffLocation,
    dropoffLocationType,
  } = useData();

  const renderRadioBtn = () => {
    return (
      <div className="flex items-center flex-wrap flex-row mb-2">
        <div
          className={`py-4 px-6 flex items-center rounded-xl font-medium text-sm cursor-pointer mr-2 my-1 sm:mr-3 ${
            dropoffLocationType === true
              ? "bg-primary-100 text-white shadow-black/10 shadow-lg"
              : "bg-white"
          }`}
          onClick={(e) => {
            setDropoffLocationType(true);
            setDropoffLocation(pickupLocation);
            setSearchedDropoffLocation(pickupLocation?.name ?? "");
            setSameDropoffLocation(true);
            setPickupLocation(null);
            setDropoffLocation(null);
            setSearchedPickupLocation("");
            setSearchedDropoffLocation("");
          }}
        >
          Same drop off
        </div>
        <div
          className={`py-4 px-6 flex items-center rounded-xl font-medium text-sm cursor-pointer mr-2 my-1 sm:mr-3 ${
            dropoffLocationType === false
              ? "bg-primary-100 text-white shadow-black/10 shadow-lg"
              : "bg-white"
          }`}
          onClick={() => {
            setDropoffLocationType(false);
            setSameDropoffLocation(false);
            // Reset values
            setPickupLocation(null);
            setDropoffLocation(null);
            setSearchedDropoffLocation("");
            setSearchedPickupLocation("");
          }}
        >
          Different drop off
        </div>
      </div>
    );
  };

  const isDdropOffdifferent = dropoffLocationType === false;

  return (
    <form className="w-full relative mt-8">
      {renderRadioBtn()}

      <div className="rounded-2xl gap-4 p-4 shadow-xl dark:shadow-2xl bg-white bg-opacity-40 dark:bg-neutral-800">
        <div className={`relative flex flex-row`}>
          <LocationInput
            placeHolder="Pick up Location"
            desc="Where are you going?"
            className="flex-1"
            sameDropOffLocation={dropoffLocationType === false}
          />

          {isDdropOffdifferent && (
            <>
              <div className="mx-2 self-center border-r border-white dark:border-slate-700 h-8"></div>
              <DropOffLocationInput
                placeHolder="City or Airport"
                desc="Drop off location"
                className="flex-1"
                divHideVerticalLineClass="-inset-x-0.5"
              />
            </>
          )}
          <>
            <div className="mx-2 self-center border-r border-white dark:border-slate-700 h-8"></div>
            <RentalCarDatesRangeInput className="flex-1" />
          </>
          <>
            <div className="mx-2 self-center border-r border-white dark:border-slate-700 h-8"></div>
            <RentalCarTimesRangeInput
              dropOffLocationType={dropoffLocationType}
              className="flex-2"
            />
          </>
        </div>
      </div>
    </form>
  );
};

export default RentalCarSearchForm;
