import React, { FC } from "react";
import Heading from "components/Heading/Heading";

export interface Statistic {
  id: string;
  heading: string;
  subHeading: string;
}

const FOUNDER_DEMO: Statistic[] = [
  {
    id: "1",
    heading: "Locations",
    subHeading:
      "Phoebe Ride Enterprises is proud to cater to the diverse transportation needs of Miami, Fort Lauderdale, Boca Raton, and West Palm Beach. Our extensive service area ensures that whether you’re exploring the bustling streets of Miami, enjoying the sunny beaches of Fort Lauderdale, experiencing the upscale charm of Boca Raton, or taking in the scenic beauty of West Palm Beach, you can rely on us for top-notch car rental solutions. ",
  },
  {
    id: "2",
    heading: "Diverse Fleet",
    subHeading:
      "Whether you’re seeking the rugged reliability of a 2023 Jeep Wrangler, the luxury and sophistication of a 2023 or 2021 Escalade, or the spacious comfort of a 2021 Yukon Denali XL equipped with an entertainment package that includes 2 TV’s, we have the perfect vehicle for you. For those looking for more economically priced options, we also provide reliable and affordable choices such as the 2018 Hyundai Elantra and the 2019 Dodge Grand Caravan.  ",
  },
  {
    id: "3",
    heading: "People & Quality",
    subHeading:
      "At Phoebe Ride Enterprises, we founded our business with a simple yet profound mission: to prioritize people. We understand that quality and service are paramount, and our commitment is to accommodate our clients the way we would want to be accommodated. By focusing on excellence and customer satisfaction, we strive to create a car rental experience that is seamless, reliable, and tailored to your needs. Because at Phoebe Ride Enterprises, you’re not just renting a car—you’re joining a community that cares. ",
  },
];

export interface SectionStatisticProps {
  className?: string;
}

const SectionStatistic: FC<SectionStatisticProps> = ({ className = "" }) => {
  return (
    <div className={`nc-SectionStatistic relative ${className}`}>
      <Heading desc="">Fast Facts</Heading>
      <div className="grid md:grid-cols-2 gap-6 lg:grid-cols-3 xl:gap-8">
        {FOUNDER_DEMO.map((item) => (
          <div
            key={item.id}
            className="p-6 bg-slate-100 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800"
          >
            <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
              {item.heading}
            </h3>
            <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
              {item.subHeading}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionStatistic;
