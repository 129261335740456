import { MegamenuItem, NavItemType } from "shared/Navigation/NavigationItem";
import ncNanoId from "utils/ncNanoId";
import __megamenu from "./jsons/__megamenu.json";

export const NAVIGATION_DEMO: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/",
    name: "Home",
    type: "dropdown",
    // children: demoChildMenus,
    isNew: true,
  },
  {
    id: ncNanoId(),
    href: "/listing-car",
    name: "Fleet",
    type: "megaMenu",
    // megaMenu: megaMenuDemo,
  },
  {
    id: ncNanoId(),
    href: "/about",
    name: "About",
    type: "megaMenu",
    // megaMenu: megaMenuDemo,
  },
  {
    id: ncNanoId(),
    href: "/start-earning",
    name: "Start Earning",
    type: "megaMenu",
    // megaMenu: megaMenuDemo,
  },
  {
    id: ncNanoId(),
    href: "/contact",
    name: "Contact",
    type: "megaMenu",
    // megaMenu: megaMenuDemo,
  },
];
