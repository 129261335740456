import { HeroSearchForm } from "components/HeroSearchForm/HeroSearchForm";
import { HeroSearchFormMobile } from "components/HeroSearchForm/HeroSearchFormMobile";
import { FC } from "react";
import { LinearGradient } from "react-text-gradients";

export interface SectionHero4Props {
  className?: string;
}

const SectionHero4: FC<SectionHero4Props> = ({ className = "" }) => {
  return (
    <div
      className={`nc-SectionHero relative ${className} lg:w-8/12 `}
      data-nc-id="SectionHero"
    >
      <div className="flex flex-col lg:flex-row lg:items-center">
        <div className="flex-shrink-0 flex flex-col items-start pb-4 lg:pb-48 xl:pr-14 lg:mr-10 xl:mr-0">
          <h2 className="px-4 sm:px-0 font-black text-6xl xl:text-7xl !leading-[114%] text-white mb-16 md:mb-4">
            Experience Tampa Bay <br className="hidden md:block" /> With
            {/* <span className="md:hidden w-full justify-center flex items-center text-[#D242D7] drop-shadow-[0_1.2px_1.2px_rgba(255,255,255,1)]">
              Pheobe
            </span> */}
            <span className="pl-4">
              <LinearGradient gradient={["to left", "#07D6FE ,#FC15D7"]}>
                Pheobe
              </LinearGradient>
            </span>
            <br className="md:hidden" />
          </h2>
          <span className="md:text-white text-2xl font-semibold hidden md:block">
            Your fresh solution for carshare and rideshare services.
          </span>
          <span className="text-base md:text-lg text-white flex"></span>
        </div>
      </div>

      <div className="hidden lg:block z-10 mb-12 lg:mb-0 lg:-mt-40 w-full">
        <HeroSearchForm />
      </div>
    </div>
  );
};

export default SectionHero4;
