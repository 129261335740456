import { FC, useState } from "react";
import { DEMO_STAY_LISTINGS } from "data/listings";
import { Link, useNavigate } from "react-router-dom";
import { BookingsModel } from "models/BookingsModel";
import ButtonPrimary from "shared/Button/ButtonPrimary";

export interface StayCardProps {
  className?: string;
  data: any;
  size?: "default" | "small";
  isUpcoming?: boolean;
  cancelFunction?: (id: string) => void;
  modifyBooking?: (id: string) => void;
  loading?: boolean;
}

const DEMO_DATA = DEMO_STAY_LISTINGS[0];

const StayCard: FC<StayCardProps> = ({
  size = "default",
  className = "",
  isUpcoming,
  data,
  cancelFunction,
  modifyBooking,
  loading,
}) => {
  const [loadingId, setLoadingId] = useState<string | null>(null);
  const navigate = useNavigate();

  const { id, pick_up_address, sub_total, fleet, thumbnail_photo_url } = data;

  const renderSliderGallery = () => {
    return (
      <div className="relative w-full">
        <img
          src={
            fleet?.first_image?.length > 0
              ? `https://fleet-management-images-upload-be.s3.amazonaws.com/${fleet.first_image}`
              : ""
          }
          alt="name"
          className="h-[200px] object-cover w-full"
        />
        {/* <BtnLikeIcon isLiked={like} className="absolute right-3 top-3 z-[1]" /> */}
        {/* {saleOff && <SaleOffBadge className="absolute left-3 top-3" />} */}
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className={size === "default" ? "p-4 space-y-4" : "p-3 space-y-2"}>
        <div className="space-y-2">
          <div className="flex items-center space-x-2">
            {/* {isAds && <Badge name="ADS" color="green" />} */}
            <h2
              className={` font-medium capitalize ${
                size === "default" ? "text-lg" : "text-base"
              }`}
            >
              <span className="line-clamp-1">{fleet.name}</span>
            </h2>
          </div>

          <span className=" text-sm">{pick_up_address}</span>
        </div>
        <div className="w-14 border-b border-neutral-100 dark:border-neutral-800"></div>
        <div className="flex justify-between items-center">
          <span className="text-base font-semibold">$ {sub_total}</span>
        </div>
        {/* {modifyBooking && (
          <ButtonPrimary
            className="bg-primary-500 w-full"
            onClick={() => {
              modifyBooking && modifyBooking(id);
            }}
          >
            View Details
          </ButtonPrimary>
        )} */}

        <ButtonPrimary
          className="w-full"
          onClick={() => {
            navigate(`/booking-details/${id}`);
          }}
        >
          View Booking
        </ButtonPrimary>
      </div>
    );
  };

  return (
    <div
      className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="StayCard"
    >
      {renderSliderGallery()}
      {renderContent()}
    </div>
  );
};

export default StayCard;
