import { FC } from "react";
import imagePng from "images/start-earning-main.png";
import img1 from "images/clientSay2.png";
import img2 from "images/clientSay3.png";
import img3 from "images/clientSay6.png";
import ButtonPrimary from "shared/Button/ButtonPrimary";

export interface Section1Props {
  className?: string;
}

const Section1: FC<Section1Props> = ({ className = "" }) => {
  return (
    <div
      className={`nc-SectionHero relative ${className}`}
      data-nc-id="SectionHero"
    >
      <div className="flex flex-col lg:flex-row lg:items-center h-full">
        <div className="flex-shrink-0 lg:w-1/2 flex flex-col items-start space-y-8 sm:space-y-10 pb-14 lg:pb-0 xl:pr-14 lg:mr-10 xl:mr-0 justify-center">
          <h2 className="font-bold text-3xl md:text-4xl xl:text-5xl !leading-[114%]">
            We Manage Your Car, YOU EARN!
          </h2>
          <span className="text-base md:text-lg text-neutral-500 dark:text-neutral-400">
            <div className="flex items-center">
              <div className="flex -space-x-4">
                <img
                  className="w-12 h-12 rounded-full border-4 border-white"
                  src={img1}
                  alt="Profile 1"
                />
                <img
                  className="w-12 h-12 rounded-full border-4 border-white"
                  src={img2}
                  alt="Profile 2"
                />
                <img
                  className="w-12 h-12 rounded-full border-4 border-white"
                  src={img3}
                  alt="Profile 3"
                />
              </div>
              <span className="ml-3">
                <strong>1000+</strong> Bookings
              </span>
            </div>
          </span>
          <div className="flex gap-10 items-center">
            <ButtonPrimary
              onClick={() => window.open("https://calendly.com/contact-phoeberides/30min")}
              className="nc-Button relative h-auto inline-flex items-center justify-center rounded-lg transition-colors px-6 py-3 text-sm sm:text-base font-medium"
            >
              Schedule A Call
            </ButtonPrimary>
            {/* <span className="text-lg font-bold leading-normal text-[#686868] cursor-pointer">
              Learn More
            </span> */}
          </div>
        </div>
        <div className="flex-grow">
          <img className="w-full" src={imagePng} alt="hero" />
        </div>
      </div>
    </div>
  );
};

export default Section1;
