import rightImg from "images/about-hero-right.png";
import SectionGridFeaturePlaces from "./SectionGridFeaturePlaces";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionHero from "containers/PageAbout/SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import { globalJson } from "global/global_json";
import SectionHero4 from "components/SectionHero/SectionHero";
import SiteHeader from "containers/SiteHeader";
import Logopic from "../../images/logo-small.png";
import Why from "containers/why/why";
import ReadytoDrive from "containers/PageAbout/ReadytoDrive";
import readytodrive from "images/readytodrive.png";
import { HeroSearchFormMobile } from "components/HeroSearchForm/HeroSearchFormMobile";
import { useEffect } from "react";
import { useData } from "data/data-provider";

function PageHome3() {
  const { formOpen } = useData();

  useEffect(() => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("user");
  }, []);

  return (
    <div className="nc-PageHome3 relative overflow-hidden">
      {/* GLASSMOPHIN */}
      {/* <BgGlassmorphism /> */}

      {/* SECTION HERO */}
      <div
        className={
          "md:bg-[url('images/hero-right.png')] bg-[url('images/hero-right-mobile.png')] justify-between flex flex-col bg-cover md:bg-center w-full h-screen"
        }
      >
        <div className="container relative px-0">
          <div className="md:pt-4">
            <div className="rounded-3xl overflow-clip">
              <SiteHeader />
            </div>
          </div>
          <div className="lg:hidden px-4 md:px-0 mb-16 md:mb-0">
            <HeroSearchFormMobile />
          </div>
          <div className="container relative lg:pb-16 lg:pt-8 md:pt-0 sm:pt-0 px-0 md:pb-0 sm:pb-0 mt-0 md:mt-24">
            <SectionHero4 />
          </div>
        </div>
        <div className="lg:hidden scroll bg-gradient-to-r from-blue-500 to-pink-500 h-[50px] flex flex-col md:flex-row justify-evenly flex-wrap pl-12 ">
          <div className="m-scroll">
            <div className=" text-white text-xs font-semibold flex items-center gap-16 scroll-mob">
              <img className="w-4 h-6" src={Logopic} />
              No 1 local car rental in Tampa
            </div>

            <div className="text-white text-xs font-semibold flex items-center gap-16 scroll-mob">
              <img className="w-4 h-6" src={Logopic} />5 star on Turo
            </div>
            <div className="text-white text-xs font-semibold flex items-center gap-16 scroll-mob">
              <img className="w-4 h-6" src={Logopic} />
              Fastest growing car rental in Tampa
              <img className="w-4 h-6 hidden lg:block" src={Logopic} />
            </div>
            <div className="text-white text-xs font-semibold flex items-center gap-16 scroll-mob">
              <img className="w-4 h-6" src={Logopic} />
              No 1 local car rental in Tampa
            </div>

            <div className="text-white text-xs font-semibold flex items-center gap-16 scroll-mob">
              <img className="w-4 h-6" src={Logopic} />5 star on Turo
            </div>
            <div className="text-white text-xs font-semibold flex items-center gap-16 scroll-mob">
              <img className="w-4 h-6" src={Logopic} />
              Fastest growing car rental in Tampa
              <img className="w-4 h-6 hidden lg:block" src={Logopic} />
            </div>
          </div>
        </div>
        <div className="hidden bottom-0 lg:flex bg-gradient-to-r from-blue-500 to-pink-500 h-[80px] lg:h-[50px] flex-col md:flex-row justify-evenly flex-wrap pl-12 ">
          <div className="text-white text-xs font-semibold flex items-center gap-16">
            <img className="w-4 h-6" src={Logopic} />
            No 1 local car rental in Tampa
          </div>
          <div className="text-white text-xs font-semibold flex items-center gap-16 scroll-mob">
            <img className="w-4 h-6" src={Logopic} />5 star on Turo
          </div>
          <div className="text-white text-xs font-semibold flex items-center gap-16 scroll-mob">
            <img className="w-4 h-6" src={Logopic} />
            Fastest growing car rental in Tampa
            <img className="w-4 h-6 hidden lg:block" src={Logopic} />
          </div>
        </div>
      </div>

      <div className="bg-[url('images/flamingo_bg.svg')] bg-[top_-1%_right_35%] md:bg-right bg-no-repeat">
        <div className="container relative py-16">
          <SectionGridFeaturePlaces />
        </div>
      </div>

      <div className="container relative" style={{ padding: "0px" }}>
        {/* ======== BG GLASS ======== */}
        <BgGlassmorphism />

        <div className="container pt-16 lg:pt-28 space-y-16 lg:space-y-28">
          <SectionHero
            rightImg={rightImg}
            heading="About Us."
            btnText=""
            subHeading={globalJson?.about_us_para}
          />
        </div>
      </div>
      <div className="bg-[url('images/flamingo_bg.svg')] bg-no-repeat md:bg-[top_20%_right_-30%] py-16 lg:py-28">
        <div className="container">
          <Why />
        </div>
      </div>
      <div className="container">
        <div className="bg-[#FFEDF9] mt-12 rounded-3xl">
          <div className="relative py-16 ">
            <SectionClientSay uniqueClassName="PageAbout_" />
          </div>
        </div>
      </div>
      <div className="bg-[url('images/flamingo_bg_flipped.svg')] mt-10 bg-no-repeat md:bg-[top_-30%_left_0%] py-16 lg:py-28">
        <div className="container">
          <div className="bg-[#FFEDF9] rounded-3xl mt-10">
            <div className="md:py-28 md:px-12 px-4 py-16">
              <ReadytoDrive
                rightImg={readytodrive}
                heading="Book your ride today!"
                btnText=""
                subHeading="If you are just arriving we can pick you up at no additional cost. Live the experience from the moment you land and we will pick you up back wherever YOU want."
                isHomePage={true}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PageHome3;
