"use client";
import { FC } from "react";
import RentalCarSearchFormMobile from "./(car-search-form)/RentalCarSearchFormMobile";
export type SearchTab = "Cars";

export interface HeroSearchFormProps {
  className?: string;
  currentTab?: SearchTab;
  currentPage?: "Cars";
}

export const HeroSearchFormMobile: FC<HeroSearchFormProps> = ({
  className = "",
}) => {
  return (
    <div
      className={`nc-HeroSearchForm w-full max-w-6xl py-5 lg:py-0 ${className}`}
    >
      <RentalCarSearchFormMobile />
    </div>
  );
};
