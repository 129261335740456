import Heading from "components/Heading/Heading";
import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import HIW1img from "images/Gotoplaces1.png";
import HIW2img from "images/Gotoplaces2.png";
import HIW3img from "images/Gotoplaces3.png";
import VectorImg from "images/VectorHIW.svg";

export interface SectionHowItWorkProps {
  className?: string;
  data?: {
    id: number;
    title: string;
    desc: string;
    img: string;
    imgDark?: string;
  }[];
}

const DEMO_DATA: SectionHowItWorkProps["data"] = [
  {
    id: 1,
    img: HIW2img,
    title: "List your car",
    desc: "Tell us about your car's year, make, model, and condition. We'll take care of professional photos and an attractive listing.",
  },
  {
    id: 2,
    img: HIW1img,
    title: "Relax and earn",
    desc: "We manage bookings, screen renters, and ensure your car is covered with top-tier insurance.",
  },
  {
    id: 3,
    img: HIW3img,
    title: "Get paid monthly",
    desc: "Receive a direct deposit every month for your car's earnings, minus a small commission fee.",
  },
];

const HowItWorks: FC<SectionHowItWorkProps> = ({
  className = "",
  data = DEMO_DATA,
}) => {
  return (
    <div
      className={`nc-SectionHowItWork  ${className}`}
      data-nc-id="SectionHowItWork"
    >
      <div className="flex flex-col mb-8 relative items-center">
        <h2 className="text-4xl font-bold ">How it works</h2>
      </div>
      <div className="mt-20 relative grid md:grid-cols-3 gap-20  mx-4">
        <img
          className="hidden md:block absolute inset-x-0 top-10"
          src={VectorImg}
          alt=""
        />
        {data.map((item) => (
          <div
            key={item.id}
            className="relative flex flex-col items-center max-w-xs mx-auto"
          >
            <NcImage containerClassName="mb-8 mx-auto" src={item.img} />

            <div className="text-center ">
              <span className="text-xl font-semibold  text-center">
                {item.title}
              </span>
              <br />
              <br />
              <span className="text-lg text-[#6B7280]  text-center">
                {item.desc}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HowItWorks;
