import React, { FC, useState } from "react";

import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";

import toast from "react-hot-toast";

import { ForgetPassFun } from "api/auth/auth";
import { AuthPages } from "routers";
import { useData } from "data/data-provider";
import { globalJson } from "global/global_json";

export interface PageForgotPassProps {
  className?: string;
  setAuthPageName: React.Dispatch<React.SetStateAction<AuthPages>>;
}

const PageForgotPass: FC<PageForgotPassProps> = ({
  className = "",
  setAuthPageName,
}) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const { setIsAuthPanelOpen } = useData();

  const handleForgetPass = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    const payload = {
      email,
      company:
        process.env.REACT_APP_ENV !== "prod"
          ? globalJson.dev_company_id
          : globalJson.prod_company_id,
    };
    const response = await ForgetPassFun(payload);

    if (response.error) {
      toast.error(response.error || "An error occurred");
    } else if (response.data) {
      toast.success(response.data.detail || "Password reset email sent!");
      setEmail("");
      setIsAuthPanelOpen(false);
    }

    setLoading(false);
  };
  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Forgot Password</title>
      </Helmet>
      <div className="popup-container mb-16 ">
        <h2 className="my-10 flex items-center justify-center text-left font-bold text-3xl lg:text-5xl leading-tight border-b-2 pb-4">
          Forgot Password
        </h2>
        <div className="max-w-md mx-auto space-y-6 mt-0 lg:mt-[156px]">
          <form className="grid grid-cols-1 gap-6" onSubmit={handleForgetPass}>
            <label className="block">
              <span className="text-[#1B2430] font-medium text-sm leading-5">
                Email address
              </span>
              <Input
                type="email"
                placeholder="example@example.com"
                className="mt-1 rounded-xl"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </label>

            <button
              type="submit"
              className="max-h-12 rounded-xl nc-Button relative h-auto inline-flex items-center justify-center transition-colors bg-primary-200 lg:px-12 py-4 px-6 text-[white] font-bold text-sm sm:text-base"
            >
              {loading ? (
                <div className="flex justify-center items-center col-span-full">
                  <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full border-t-transparent border-gray-300"></div>
                </div>
              ) : (
                "Send Email"
              )}
            </button>
          </form>

          <span className="block text-center text-text-color dark:text-text-color">
            New user? {` `}
            <span
              className="font-bold hover:cursor-pointer"
              onClick={() => {
                setAuthPageName(AuthPages.Signup);
              }}
            >
              Create an account
            </span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageForgotPass;
