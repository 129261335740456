import HubSpotBot from "components/Chatbot/Chatbot";
import { BrowserRouter } from "react-router-dom";
import MyRouter from "routers/index";

function App() {
  return (
    <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
      {/* <HubSpotBot /> */}
      <BrowserRouter>
        <MyRouter />
      </BrowserRouter>
    </div>
  );
}

export default App;
