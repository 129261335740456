import CarCriteriaImg from "images/car-criteria.png";
import Badge from "shared/Badge/Badge";

const CarCriteria = () => {
  return (
    <div className=" text-[#3B3B3B] py-16 px-8 md:px-20">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-10 items-center">
        {/* Left Column: Text Section */}
        <div className="space-y-8">
          <h2 className="text-4xl font-bold ">
            Car Criteria{" "}
            <div className="text-gray-600 text-lg font-normal font-['Poppins'] leading-7 mt-4">
              Ensuring quality and performance
            </div>
          </h2>

          {/* Feature 1: Diverse Fleet of Vehicles */}
          <div className="flex flex-col items-start gap-3">
            <div className="flex items-center gap-4">
              <Badge name="01" className="h-6" />
              <h3 className="text-xl font-bold text-[#3B3B3B]">Inspection </h3>
            </div>
            <p className="text-[#3B3B3B] max-w-[80%] ml-1">
              Vehicles require a multi-point inspection, no warning lights, and
              no recalls.
            </p>
          </div>

          <div className="flex flex-col items-start gap-3">
            <div className="flex items-center gap-4">
              <Badge color="pink" name="02" className="h-6" />
              <h3 className="text-xl font-bold text-[#3B3B3B]">Model year </h3>
            </div>
            <p className="text-[#3B3B3B] max-w-[80%] ml-1">
              Cars must be from the year 2018 or newer.
            </p>
          </div>

          {/* Feature 3: Affordable Pricing */}
          <div className="flex flex-col items-start gap-3">
            <div className="flex items-center gap-4">
              <Badge color="red" name="03" className="h-6" />
              <h3 className="text-xl font-bold text-[#3B3B3B]">Value </h3>
            </div>
            <p className="text-[#3B3B3B] max-w-[80%] ml-1">
              The value of the cars should not exceed $75,000.
            </p>
          </div>
        </div>
        <div className="relative">
          <img
            src={CarCriteriaImg}
            alt="Car Criteria"
            className="rounded-xl object-cover"
          />
        </div>
      </div>
    </div>
  );
};

export default CarCriteria;
