import rightImg from "images/about-hero-right.png";
import { FC } from "react";
import SectionStatistic from "./SectionStatistic";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionHero from "./SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import { globalJson } from "global/global_json";
import ReadytoDrive from "./ReadytoDrive";
import readytodrive from "images/readytodrive.png";

export interface PageAboutProps {
  className?: string;
}

const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>About</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <SectionHero
          rightImg={rightImg}
          heading="About Us."
          btnText=""
          subHeading={globalJson?.about_us_para}
        />
        <div className="bg-[#FFEDF9] mt-12 rounded-3xl">
          <div className="relative py-16 mb-20 lg:mb-36">
            {/* <BackgroundSection /> */}
            <SectionClientSay uniqueClassName="PageAbout_" />
          </div>
        </div>
        <SectionStatistic />
        <div className="bg-[#FFEDF9] mt-12 rounded-3xl">
          <div className="p-8 md:p-16 ">
            {/* <BackgroundSection /> */}
            <ReadytoDrive
              rightImg={readytodrive}
              heading="Book your ride today!"
              btnText=""
              subHeading="If you are just arriving we can pick you up at no additional cost. Live the experience from the moment you land and we will pick you up back wherever YOU want."
              isHomePage={false}
            />
          </div>
        </div>{" "}
      </div>
    </div>
  );
};

export default PageAbout;
