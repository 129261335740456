import { FC } from "react";
import Logo from "shared/Logo/Logo";
import Navigation from "shared/Navigation/Navigation";

import MenuBar from "shared/MenuBar/MenuBar";

import { useNavigate } from "react-router-dom";
import { useData } from "data/data-provider";

export interface MainNav1Props {
  className?: string;
}

const MainNav1: FC<MainNav1Props> = ({ className = "" }) => {
  const { user, setIsAuthPanelOpen } = useData();
  const navigate = useNavigate();
  return (
    <div
      className={`nc-MainNav1 relative z-10 ${className} max-w-[1450px] mx-auto`}
    >
      <div className="px-4  py-4 lg:py-5 relative flex justify-between items-center">
        <div className="flex justify-start items-center space-x-4 sm:space-x-10">
          <Logo />
        </div>
        <div className="hidden lg:flex items-end">
          <Navigation />
        </div>

        {/* <div className="lg:hidden flex-[3] max-w-lg !mx-auto md:px-3">
          <HeroSearchForm2MobileFactory />
        </div> */}
        <div className="flex lg:hidden items-center ml-auto">
          {/* <SwitchDarkMode /> */}
          <div className="px-0.5 " />
          <MenuBar />
        </div>
      </div>
    </div>
  );
};

export default MainNav1;
