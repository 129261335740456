import React, { FC, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";

export interface SectionHeroProps {
  className?: string;
  rightImg: string;
  heading: ReactNode;
  subHeading: string;
  btnText: string;
  isHomePage: boolean;
}

const TurnCarToAsset: FC<SectionHeroProps> = ({
  className = "",
  rightImg,
  heading,
  subHeading,
  btnText,
  isHomePage,
}) => {
  const navigate = useNavigate(); // Use navigate to handle routing
  return (
    <div
      className={`nc-SectionHero relative ${className}  `}
      data-nc-id="SectionHero"
    >
      <div className="flex flex-col lg:flex-row space-y-14 lg:space-y-0 lg:space-x-10 items-center relative text-center lg:text-left">
        <div className="w-screen max-w-full xl:max-w-xl space-y-5 lg:space-y-7">
          <h2 className="text-3xl !leading-tight font-bold md:text-4xl xl:text-5xl dark:text-[#E5E7EB]">
            {heading}
          </h2>
          <span className="block text-base xl:text-lg dark:text-neutral-400 md:text-justify">
            {subHeading}
          </span>
          <ButtonPrimary className="nc-Button relative h-auto inline-flex items-center justify-center rounded-lg transition-colors px-6 py-3 text-sm sm:text-base font-medium">
            Join Us
          </ButtonPrimary>
        </div>
        <div className="flex justify-center lg:justify-end">
          <img className="w-full rounded-xl" src={rightImg} alt="" />
        </div>
      </div>
    </div>
  );
};

export default TurnCarToAsset;
