import StartEarningTodayImg from "images/start-earning-today.png";
import Badge from "shared/Badge/Badge";

const StartEarningToday = () => {
  return (
    <div className=" text-[#3B3B3B] py-16 px-8 md:px-20">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-10 items-center">
        {/* Left Column: Text Section */}
        <div className="space-y-8">
          <h2 className="text-4xl font-bold ">
            Start Earning Today!
            <div className="text-gray-600 text-lg font-normal font-['Poppins'] leading-7 mt-4">
              The average Phoebe car owner earns over{" "}
              <span className="text-gray-600 text-lg font-bold font-['Poppins'] leading-7">
                $634
                <span className="text-gray-600 text-lg font-normal font-['Poppins'] leading-7">
                  {" "}
                  per month! Join our network of happy car owners and:
                </span>
              </span>
            </div>
          </h2>

          {/* Feature 1: Diverse Fleet of Vehicles */}
          <div className="flex flex-col items-start gap-3">
            <div className="flex items-center gap-4">
              <Badge name="01" className="h-6" />
              <h3 className="text-xl font-bold text-[#3B3B3B]">Free up cash</h3>
            </div>
            <p className="text-[#3B3B3B] max-w-[80%] ml-1">
              Put your car to work and generate income you can use for anything
              you desire.
            </p>
          </div>

          <div className="flex flex-col items-start gap-3">
            <div className="flex items-center gap-4">
              <Badge color="pink" name="02" className="h-6" />
              <h3 className="text-xl font-bold text-[#3B3B3B]">
                No hassle rentals
              </h3>
            </div>
            <p className="text-[#3B3B3B] max-w-[80%] ml-1">
              We handle everything - you don't have to deal with renters
              directly.
            </p>
          </div>

          {/* Feature 3: Affordable Pricing */}
          <div className="flex flex-col items-start gap-3">
            <div className="flex items-center gap-4">
              <Badge color="red" name="03" className="h-6" />
              <h3 className="text-xl font-bold text-[#3B3B3B]">
                Peace of mind
              </h3>
            </div>
            <p className="text-[#3B3B3B] max-w-[80%] ml-1">
              Your car is protected with comprehensive insurance while it's
              rented.
            </p>
          </div>
        </div>
        <div className="relative">
          <img
            src={StartEarningTodayImg}
            alt="Start Earning Today"
            className="rounded-xl object-cover"
          />
        </div>
      </div>
    </div>
  );
};

export default StartEarningToday;
