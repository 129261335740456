"use client";

import { getLocationData } from "api/locations/locations";
import { getAvailableVehicleData, getVehicleData } from "api/vehicles/vehicle";
import { LocationListingModal } from "models/LocationModal";
import { User } from "models/userModal";
import { CarListingModal } from "models/vehicleModal";
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { addDays } from "date-fns";
import toast from "react-hot-toast";
import {
  CompanyInfoResponse,
  VehiclePayload,
  VehicleResponse,
} from "api/types";
import { getCompanyInfoFun } from "api/company/company";
import { applyCoupon, removeCoupon } from "api/coupon/coupon";
import { CreateStripeIntent, SavePaymentMethod } from "api/stripe/stripe";

interface BonzahProduct {
  name: string;
  productType: string;
  currency: string;
  regularPrice: number;
  discountPercent: number;
  finalPrice: number;
  totalPrice: number;
  numberOfDays: number;
  priceBasis: string;
  supplier: string;
  isSelected?: boolean;
  productInfo: {
    descriptionOfCoverageUrl: string;
  };
  additionalInfo?: Record<string, any>;
}
interface DataContextType {
  user: User | null;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
  formOpen: boolean;
  setFormOpen: React.Dispatch<React.SetStateAction<boolean>>;
  ourVehicles: CarListingModal[];
  setOurVehicles: React.Dispatch<React.SetStateAction<CarListingModal[]>>;
  ourVehiclesLoading: boolean;
  ourLocation: LocationListingModal[];
  setOurLocation: React.Dispatch<React.SetStateAction<LocationListingModal[]>>;
  ourCompany: any;
  setOurCompany: React.Dispatch<React.SetStateAction<any>>;
  pickupLocation: any;
  setPickupLocation: React.Dispatch<
    React.SetStateAction<LocationListingModal | null>
  >;
  stripeVerificationLoading: boolean;
  setStripeVerificationLoading: React.Dispatch<React.SetStateAction<boolean>>;
  dropOffLocation: any;
  setDropoffLocation: React.Dispatch<
    React.SetStateAction<LocationListingModal | null>
  >;
  dropoffLocationType: boolean;
  setDropoffLocationType: React.Dispatch<React.SetStateAction<boolean>>;
  sameDropoffLocation: boolean;
  setSameDropoffLocation: React.Dispatch<React.SetStateAction<boolean>>;
  bookingdetails: any;
  setBookingdetails: React.Dispatch<React.SetStateAction<any>>;
  invoice: any;
  setInvoice: React.Dispatch<React.SetStateAction<any>>;
  availableFleet: VehicleResponse[] | null;
  loadingAvailableFleet: boolean;
  isAuthPanelOpen: boolean;
  extras: any;
  setExtras: React.Dispatch<React.SetStateAction<[]>>;
  selectedExtras: any;
  setSelectedExtras: React.Dispatch<React.SetStateAction<Set<number>>>;
  selectedBonzahProducts: any;
  setSelectedBonzahProducts: React.Dispatch<React.SetStateAction<Set<any>>>;
  bonzahProducts: any;
  setBonzahProducts: React.Dispatch<React.SetStateAction<BonzahProduct[]>>;
  setIsAuthPanelOpen: React.Dispatch<React.SetStateAction<boolean>>;
  searchedPickupLoction: string;
  checkCouponResponseLoading: boolean;
  allBookings: any;
  setAllBookings: React.Dispatch<React.SetStateAction<any>>;
  setCheckCouponResponseLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setSearchedPickupLocation: React.Dispatch<React.SetStateAction<string>>;
  couponResponse: any;
  searchedDropoffLocation: string;
  setSearchedDropoffLocation: React.Dispatch<React.SetStateAction<string>>;
  setCouponResponse: React.Dispatch<React.SetStateAction<any>>;
  callCouponAPI: (couponCode: string, bookingId: string) => Promise<void>;
  callremoveCouponAPI: (couponCode: string, bookingId: string) => Promise<void>;
  selectedCar: CarListingModal | null;
  setSelectedCar: React.Dispatch<React.SetStateAction<CarListingModal | null>>;
  callAvailableFleetAPI: (navigate: any) => Promise<void>;
  Logout: (navigate: any) => void;
  checkout: (bookingId: any) => Promise<any>;
  saveCard: (paymentMethodId: any) => Promise<any>;

  startDate: Date;
  startHours: string;
  startMinutes: string;
  startPeriod: string;
  endDate: Date;
  endHours: string;
  endMinutes: string;
  endPeriod: string;

  firstName: string;
  setFirstName: React.Dispatch<React.SetStateAction<string>>;
  lastName: string;
  setLastName: React.Dispatch<React.SetStateAction<string>>;
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  phone: string;
  setPhone: React.Dispatch<React.SetStateAction<string>>;

  setStartDate: React.Dispatch<React.SetStateAction<Date>>;
  setStartHours: React.Dispatch<React.SetStateAction<string>>;
  setStartMinutes: React.Dispatch<React.SetStateAction<string>>;
  setStartPeriod: React.Dispatch<React.SetStateAction<string>>;
  setEndDate: React.Dispatch<React.SetStateAction<Date>>;
  setEndHours: React.Dispatch<React.SetStateAction<string>>;
  setEndMinutes: React.Dispatch<React.SetStateAction<string>>;
  setEndPeriod: React.Dispatch<React.SetStateAction<string>>;
}

const DataContext = createContext<DataContextType | undefined>(undefined);

export const formatDateTime = (
  date: Date,
  hours: string,
  minutes: string,
  period: string
): string => {
  // Convert string hours and minutes to numbers
  let hoursInt = parseInt(hours, 10);
  const minutesInt = parseInt(minutes, 10);

  // Handle the conversion from 12-hour format to 24-hour format
  if (period === "PM" && hoursInt !== 12) {
    hoursInt += 12; // Convert PM hours to 24-hour format, except for 12 PM
  } else if (period === "AM" && hoursInt === 12) {
    hoursInt = 0; // Handle midnight case (12 AM)
  }

  // Set the time on the date object using local time
  date.setHours(hoursInt, minutesInt, 0, 0);

  // Get the local date and time in the desired format
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hour = String(date.getHours()).padStart(2, "0");
  const minute = String(date.getMinutes()).padStart(2, "0");
  const second = String(date.getSeconds()).padStart(2, "0");

  // Return the formatted date string in local time
  return `${year}-${month}-${day}T${hour}:${minute}:${second}`;
};

export function DataProvider({ children }: any) {
  const [user, setUser] = useState<User | null>(null);
  const [formOpen, setFormOpen] = useState<boolean>(false);
  const [ourVehicles, setOurVehicles] = useState<CarListingModal[]>([]);
  const [ourLocation, setOurLocation] = useState<LocationListingModal[]>([]);
  const [ourCompany, setOurCompany] = useState<any>([]);
  const [pickupLocation, setPickupLocation] =
    useState<LocationListingModal | null>(null);
  const [dropOffLocation, setDropoffLocation] =
    useState<LocationListingModal | null>(null);
  const [searchedPickupLoction, setSearchedPickupLocation] =
    useState<string>("");
  const [searchedDropoffLocation, setSearchedDropoffLocation] =
    useState<string>("");
  const [ourVehiclesLoading, setOurVehiclesLoading] = useState<boolean>(false);
  const [dropoffLocationType, setDropoffLocationType] = useState<boolean>(true);
  const [sameDropoffLocation, setSameDropoffLocation] = useState<boolean>(true);
  const [isAuthPanelOpen, setIsAuthPanelOpen] = useState<boolean>(false);
  const [couponResponse, setCouponResponse] = useState<any>(null);
  const [checkCouponResponseLoading, setCheckCouponResponseLoading] =
    useState<boolean>(false);
  const [stripeVerificationLoading, setStripeVerificationLoading] =
    useState<boolean>(false);
  const [selectedCar, setSelectedCar] = useState<CarListingModal | null>(null);
  const [bookingdetails, setBookingdetails] = useState<any>(null);
  const [invoice, setInvoice] = useState<any>([]);
  const [extras, setExtras] = useState<any>([]);
  const [selectedExtras, setSelectedExtras] = useState<Set<number>>(new Set());
  const [allBookings, setAllBookings] = useState<any>();
  const [selectedBonzahProducts, setSelectedBonzahProducts] = useState<
    Set<any>
  >(new Set());

  const [bonzahProducts, setBonzahProducts] = useState<BonzahProduct[]>([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [startDate, setStartDate] = useState<Date>(() => {
    const newDate = addDays(new Date(), 2); // Create a new date 2 days from now
    newDate.setHours(10, 0, 0, 0); // Set the time to 10 AM (hours, minutes, seconds, milliseconds)
    return newDate;
  });
  const [startHours, setStartHours] = useState("10");
  const [startMinutes, setStartMinutes] = useState("00");
  const [startPeriod, setStartPeriod] = useState("AM");

  const [endDate, setEndDate] = useState<Date>(() => {
    const newDate = addDays(new Date(), 4); // Create a new date 2 days from now
    newDate.setHours(17, 0, 0, 0); // Set the time to 10 AM (hours, minutes, seconds, milliseconds)
    return newDate;
  });

  const [endHours, setEndHours] = useState("05");
  const [endMinutes, setEndMinutes] = useState("00");
  const [endPeriod, setEndPeriod] = useState("PM");

  const [availableFleet, setAvailableFleet] = useState<VehicleResponse[]>([]);
  const [loadingAvailableFleet, setLoadingAvailableFleet] =
    useState<boolean>(false);

  useEffect(() => {
    callOurVehicleAPI();
    callOurLocationAPI();
    callOurCompanyAPI();
  }, []);

  // Load user data from localStorage
  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      try {
        setUser(JSON.parse(storedUser));
      } catch (error) {
        console.error("Failed to parse user data:", error);
      }
    }
  }, []);

  // Fetch vehicle data from API
  const callOurVehicleAPI = async () => {
    setOurVehiclesLoading(true);
    try {
      const data = await getVehicleData();

      if (Array.isArray(data)) {
        // Filter for active vehicles before setting state
        setOurVehicles(
          data.filter((vehicle) => vehicle.active) as CarListingModal[]
        );
      } else if (data?.data && Array.isArray(data.data)) {
        // Filter for active vehicles before setting state
        setOurVehicles(
          data.data.filter((vehicle) => vehicle.active) as CarListingModal[]
        );
      } else {
        throw new Error("Invalid data format");
      }
    } catch (error: any) {
      toast.error(error.message || "Failed to fetch vehicles");
      console.error("Vehicle API error:", error);
    } finally {
      setOurVehiclesLoading(false);
    }
  };

  const callAvailableFleetAPI = async (navigate: (path: string) => void) => {
    setLoadingAvailableFleet(true);

    const vehiclePayload: VehiclePayload = {
      available: true,
      start_time: formatDateTime(
        startDate,
        startHours,
        startMinutes,
        startPeriod
      ),
      end_time: formatDateTime(endDate, endHours, endMinutes, endPeriod),
      pickup_location: pickupLocation?.id,
    };

    // Helper function to transform CarListingModal to VehicleResponse
    const transformCarListingToVehicleResponse = (
      carListing: CarListingModal[]
    ): VehicleResponse[] => {
      return carListing.map((car) => ({
        ...car,
      }));
    };

    try {
      const result = await getAvailableVehicleData(vehiclePayload);

      setLoadingAvailableFleet(false);

      if (result.error) {
        toast.error("Error Loading Fleets");
        setLoadingAvailableFleet(false);
      } else if (result.data) {
        // Filter for active vehicles before transforming the result
        const activeVehicles = (result.data as CarListingModal[]).filter(
          (vehicle) => vehicle.active
        );
        setAvailableFleet(transformCarListingToVehicleResponse(activeVehicles));
      }
    } catch (error) {
      console.error("An unexpected error occurred:", error);
      setLoadingAvailableFleet(false);
    } finally {
      setFormOpen(false);
      navigate("/available-car");
      setLoadingAvailableFleet(false);
    }
  };

  const callCouponAPI = async (couponCode: string, bookingId: string) => {
    try {
      setCheckCouponResponseLoading(true);
      const data: any = await applyCoupon(couponCode, bookingId);
      if (data?.error?.length > 0) {
        toast.error(data?.error);
      } else {
        setCouponResponse(data as any);
        if (data && data) {
          setBookingdetails((prevBookingDetail: any) => ({
            ...prevBookingDetail,
            invoice: data, // Assuming the API returns the updated invoice in this format
          }));
        }
      }
    } catch (error: any) {
      toast.error("Invalid Promo Code"); // Or handle errors in a more sophisticated way
    } finally {
      setCheckCouponResponseLoading(false);
    }
  };
  const callremoveCouponAPI = async (couponCode: string, bookingId: string) => {
    try {
      const data = await removeCoupon(couponCode, bookingId);

      if (data && data) {
        setBookingdetails((prevBookingDetail: any) => ({
          ...prevBookingDetail,
          invoice: data, // Assuming the API returns the updated invoice in this format
        }));
      }
    } catch (error: any) {
      toast.error("Error occur removing Promo Code"); // Or handle errors in a more sophisticated way
    } finally {
    }
  };

  const saveCard = async (
    paymentMethodId: any
  ): Promise<{ data?: any; error?: string } | undefined> => {
    const payload = { payment_method_id: paymentMethodId };
    try {
      const res = await SavePaymentMethod(payload);
      return res; // Return the result which can have data or error
    } catch (error: any) {
      toast.error(error.message || "Failed to save the payment method");
      console.error("Save Card error:", error);
      return undefined; // Return undefined in case of an error
    }
  };

  const checkout = async (
    bookingId: any
  ): Promise<{ data?: any; error?: string } | undefined> => {
    const payload = { booking_id: bookingId };

    try {
      const res = await CreateStripeIntent(payload);
      return res; // Return the result which can have data or error
    } catch (error: any) {
      toast.error(error.message || "Failed to process checkout");
      console.error("Checkout error:", error);
      return undefined; // Return undefined in case of an error
    }
  };

  // Fetch location data from API
  const callOurLocationAPI = async () => {
    try {
      const data = await getLocationData();
      if (Array.isArray(data)) {
        setOurLocation(data as LocationListingModal[]);
      } else {
        throw new Error("Unable to fetch location data");
      }
    } catch (error: any) {
      toast.error(error.message || "Failed to fetch locations");
      console.error("Location API error:", error);
    }
  };
  const callOurCompanyAPI = async () => {
    try {
      const data = await getCompanyInfoFun();

      setOurCompany(data);
    } catch (error: any) {
      toast.error(error.message || "Failed to fetch company");
      console.error("Company API error:", error);
    }
  };

  const Logout = (navigate: any) => {
    localStorage.removeItem("user");
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    setUser(null);
    setSelectedCar(null);
    navigate("/");
  };

  return (
    <DataContext.Provider
      value={{
        user,
        setUser,
        formOpen,
        setFormOpen,
        ourVehicles,
        setOurVehicles,
        ourVehiclesLoading,
        isAuthPanelOpen,
        setIsAuthPanelOpen,
        ourLocation,
        checkCouponResponseLoading,
        setCheckCouponResponseLoading,
        couponResponse,
        selectedExtras,
        setSelectedExtras,
        selectedBonzahProducts,
        setSelectedBonzahProducts,
        setOurLocation,
        bookingdetails,
        setBookingdetails,
        ourCompany,
        setOurCompany,
        extras,
        setExtras,
        setBonzahProducts,
        bonzahProducts,
        setCouponResponse,
        availableFleet,
        allBookings,
        setAllBookings,
        pickupLocation,
        sameDropoffLocation,
        setSameDropoffLocation,
        setPickupLocation,
        dropOffLocation,
        setDropoffLocation,
        searchedDropoffLocation,
        setStripeVerificationLoading,
        stripeVerificationLoading,
        searchedPickupLoction,
        setSearchedDropoffLocation,
        firstName,
        lastName,
        email,
        phone,
        setFirstName,
        setLastName,
        setEmail,
        setPhone,
        setSearchedPickupLocation,
        dropoffLocationType,
        setDropoffLocationType,
        invoice,
        setInvoice,
        loadingAvailableFleet,
        callCouponAPI,
        callremoveCouponAPI,
        selectedCar,
        setSelectedCar,
        callAvailableFleetAPI: callAvailableFleetAPI,
        startDate,
        startMinutes,
        startHours,
        startPeriod,
        saveCard,
        endDate,
        endMinutes,
        endPeriod,
        endHours,
        setStartDate,
        setStartHours,
        checkout,
        setStartMinutes,
        setStartPeriod,
        setEndDate,
        setEndHours,
        setEndMinutes,
        setEndPeriod,
        Logout,
      }}
    >
      {children}
    </DataContext.Provider>
  );
}

export function useData() {
  const context = useContext(DataContext);
  if (context === undefined) {
    throw new Error("useData must be used within a DataProvider");
  }
  return context;
}
