import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import TurnCarToAssetImg from "images/start-earning.png";
import Section1 from "./Section1";
import StartEarningToday from "./StartEarningToday";
import HowItWorks from "./HowItWorks";
import CarCriteria from "./CarCriteria";
import TurnCarToAsset from "./TurnCarToAsset";
import PageContact from "containers/PageContact/PageContact";
import { Helmet } from "react-helmet";
import WhyUs from "./WhyUs";

function StartEarning() {
  return (
    <div className="nc-PageHome3 relative overflow-hidden">
      <Helmet>
        <title>Start Earning</title>
      </Helmet>
      {/* GLASSMOPHIN */}
      <BgGlassmorphism />

      {/* SECTION HERO */}
      <div className="container relative lg:pb-16 lg:pt-8 md:pt-0 sm:pt-0 px-0 md:pb-0 sm:pb-0 ">
        <div className="container">
          <Section1 className="" />
        </div>
      </div>

      <div className="container relative p-8 md:p-16">
        <StartEarningToday />
      </div>
      <div className="container relative p-8 md:p-16">
        <HowItWorks />
      </div>
      <div className="container">
        <div className="bg-[#FFEDF9] mt-12 rounded-3xl">
          <div className="relative py-16 ">
            <WhyUs />
          </div>
        </div>
      </div>
      <div className="container relative p-8 md:p-16">
        <CarCriteria />
      </div>
      <div className="container">
        <div className="bg-[#FFEDF9] mt-12 rounded-3xl">
          <div className="relative py-16 ">
            <SectionClientSay uniqueClassName="PageAbout_" />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="bg-[#FFEDF9] mt-24 rounded-3xl  p-8 md:p-16">
          <div className="relative py-16 ">
            <TurnCarToAsset
              rightImg={TurnCarToAssetImg}
              heading="Turn your car into an Earning Asset"
              btnText=""
              subHeading="Start earning $XXX per month. Click on the button below to book an online appointment, or call us at 813-285-4441"
              isHomePage={true}
            />
          </div>
        </div>
      </div>
      <div className="relative p-8 md:p-16">
        <PageContact asSection={true} />
      </div>
    </div>
  );
}

export default StartEarning;
