import { ReactNode } from "react";
import { imageGallery as listingStayImageGallery } from "./listing-car-detail/constant";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import ListingImageGallery from "components/ListingImageGallery/ListingImageGallery";
import { ListingGalleryImage } from "components/ListingImageGallery/utils/types";
import { useData } from "data/data-provider";

const DetailPagetLayout = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const modal = searchParams?.get("modal");
  const thisPathname = useLocation().pathname;

  const { selectedCar } = useData();

  const handleCloseModalImageGallery = () => {
    let params = new URLSearchParams(document.location.search);
    params.delete("modal");
    navigate(`${thisPathname}/?${params.toString()}`);
  };

  const getImageGalleryListing = () => {
    if (thisPathname?.includes("/listing-stay-detail")) {
      return listingStayImageGallery;
    }
    if (thisPathname?.includes("/listing-car-detail")) {
      return selectedCar?.fleet_photos.map(
        (item, index): ListingGalleryImage => {
          return {
            id: index,
            url: `https://fleet-management-images-upload-be.s3.amazonaws.com/${item?.photo_url}`,
          };
        }
      );
    }
    return [];
  };

  return (
    <div className="ListingDetailPage">
      <ListingImageGallery
        isShowModal={modal === "PHOTO_TOUR_SCROLLABLE"}
        onClose={handleCloseModalImageGallery}
        images={getImageGalleryListing()}
      />

      <div className="container ListingDetailPage__content">{children}</div>

      {/* OTHER SECTION */}
      <div className="container py-24 lg:py-32"></div>
    </div>
  );
};

export default DetailPagetLayout;
