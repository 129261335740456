import clientSay1 from "images/clientSay1.png";
import clientSay4 from "images/clientSay4.png";
import clientSay5 from "images/clientSay5.png";

export const globalJson = {
  //
  prod_company_id: 35,
  dev_company_id: 35, //
  // apiUtil auth header key
  authToken: "Bearer 156|58t8SSr5MhZhuScrt9qczI7ywigBQQzfLGDO24SM",
  companyId: "a75b8a81-d900-402f-b0c3-5a362ff0f652",
  stripePublishableKey:
    "pk_live_51LdnoABqYa4ICTHoC6CjEgImHYOvUXXPs8psg8jZqXPxRwBt99nQEWtFFJj2n3ublkCJa0S8pYzDtvStF2JxUPzU00uWCskvlb",
  mapKey: "AIzaSyDKEntg7JydrxI7zr2tQL5OYcS5M-er-bc",
  residenceStateCode: "FL",

  //email confirmation admin token
  adminEmailToken:
    "vuRJfhz4wO5Odhxv682si70x95UA7zOxqz7sK2r7L8LJMXTsWiKM53edU24y4LYVgFqj4oI3OmaRFzDWoXA9GdCqNfyOciVYw9GO0kbDOepigLabYZDgnkw0ih6uLUgi",
  websiteLink: "https://mopacrental.com",
  sender: "mopac",
  ccEmail: "smartprentals@gmail.com",

  // apiUtil sendNewsLetterEmail
  util_serviceId: "service_0qh0217",
  util_templateId: "template_k4swowo",
  util_userId: "XU0TvMOEGy9VJQE5T",
  util_privateKey: "b3uzElNArs8Koz0Y9zWvo",

  // apiUtil getBonzahToken
  client_secret_dev:
    "SLxANOhVR/O9HK7QDelJBvbsz+i/XW5zVXYe49JeHyaHbf2RAvKunJyFWebq5ImbJpRDdUaEIp3Zu9fDL5Yt7VMX7lqe8iCMBzveJp1RfCM+Y+l5SW3iMMhO9h/8GDAwaCpZmg==",
  client_id_dev:
    "ab560362-ffc6-4dc7-a16a-fd43845deefc-20240522074089-vendor@bonzah.com",
  client_secret_prod:
    "SUnRy0BcS3KlKZFKcZljUTgxjm16B+lkDObgCvwe+Cyn4NIC1AqXwJgG4M4Z+6OyWphxUbNkrKr2F1WL9jxuhW3jLn9s0gBb8qF3ZxV4A18oWelsjDAg7CC1UHkPMQNH0A6+OA==",
  client_id_prod:
    "3646ae7f-dd0a-4aed-bd7e-f2d4bc9b3036-20240522501529-vendor@bonzah.com",

  about_us_para:
    "Experience Tampa Bay with Phoebe's innovative carshare and rideshare services, designed for convenience and affordability, while supporting local entrepreneurs and valuing drivers' contributions",

  clientSays_arr: [
    {
      id: 1,
      clientName: "Terry M",
      clientAddress: "Dodge Grand Caravan 2019",
      content:
        "Wonderful rental, they made it easy to find and provided just what we needed, would recommend them to anyone 5star",
      img: clientSay1,
    },
    {
      id: 2,
      clientName: "Osama I",
      clientAddress: "LondDodge Grand Caravan 2019",
      content:
        "Very smooth pick-up and drop-off. Host was responsive. Much easier and quicker than using a rental company. Overall great experience!",
      img: clientSay5,
    },
    {
      id: 3,
      clientName: "Mikhail K",
      clientAddress: "Dodge Grand Caravan 2019",
      content:
        "That was my first time in car-sharing. It was great. My host was really nice. His car was in excellent condition and very clean. He was in time for drop off and pick up. I appreciate that so much!",
      img: clientSay4,
    },
  ],

  //social links
  facebook: "https://www.facebook.com/share/WBkbDMTGs23S3mXy/?mibextid=LQQJ4d",
  instagram: "https://www.instagram.com/mopacrental?igsh=MTlvZnkwcWQ3bHgzMA==",

  //contact
  address: "4830 W Kennedy Blvd. Tampa, FL 33609",
  addressLink:
    "https://www.google.com/maps/place/4830+W+Kennedy+Blvd,+Tampa,+FL+33609,+USA/data=!4m2!3m1!1s0x88c2c2e4b61fbe87:0x7fa31f86943d0895?sa=X&ved=1t:242&ictx=111",
  phone: "813-285-4441",
  email: "contact@phoeberides.com",
};

export const scriptHubSpot = document.createElement("script");
scriptHubSpot.src = "//js-na1.hs-scripts.com/46394417.js";
scriptHubSpot.async = true;
scriptHubSpot.defer = true;
scriptHubSpot.id = "hs-script-loader";
